import React from "react";
import { Card, Button, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import CommonTabBar from "../components/CommonTabBar";
import PersonCard from "../components/PersonCard";
import { useAuth } from "../contexts/AuthContext"; // 引入 AuthContext

const Home = () => {
    const navigate = useNavigate();
    const { nickname, serverName, logout, uid, serverId } = useAuth(); // 使用全局状态

    // 登出逻辑
    const handleLogout = () => {
        logout();
        Toast.show({ content: "已登出" });
        navigate("/login");
    };

    return (
        <div style={{ paddingBottom: "60px" }}>
            {" "}
            {/* 为了腾出空间给 TabBar */}
            <Card style={{ margin: "16px", padding: "16px" }}>
                {/* 包含用户信息和操作按钮的PersonCard组件 */}
                <PersonCard nickName={nickname} serverName={serverName} uid={uid} serverId={serverId}/>

                {/* 添加一个登出按钮 */}
                <Button block color="danger" style={{ marginTop: "16px" }} onClick={handleLogout}>
                    登出
                </Button>
            </Card>
            {/* 底部固定的 TabBar */}
            <div style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
                <CommonTabBar />
            </div>
        </div>
    );
};

export default Home;
