import React, { useState, useEffect } from "react";
import { List, Avatar, Modal, Button, Space, Toast } from "antd-mobile";
import { FaPlay, FaStop } from "react-icons/fa";
import { CiWarning } from "react-icons/ci";
import { MdRestartAlt } from "react-icons/md";
import { SetOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router-dom";
import { getContainerStatus, subscribeContainerStatus, runContainer, stopContainer, restartContainer } from "../api/container"; // 假设已封装 API 调用

const demoAvatarImages = ["http://img.duoziwang.com/2018/17/05211624504708.jpg"];

const PersonCard = ({ nickName, serverName, uid, serverId, status}) => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const [actionType, setActionType] = useState("");
    const [containerStatus, setContainerStatus] = useState(status || "unknown");

    useEffect(() => {
        let eventSource;
        const fetchStatus = async () => {
            try {
                const status = await getContainerStatus(uid, serverId); // 发送请求获取容器状态
                setContainerStatus(status); // 更新容器状态
            } catch (error) {
                console.log("Fail to fetch container status");
            }
        };

        // 如果没有传入 status，则执行 fetchStatus 并订阅 SSE
        if (!status) {
            fetchStatus();

            // 使用 SSE 订阅容器事件
            eventSource = subscribeContainerStatus(uid, serverId, (newStatus) => {
                setContainerStatus(newStatus); // 更新容器状态
            });
        } else {
            // 如果传入了 status，则直接使用传入的 status
            setContainerStatus(status);
        }

        // 组件卸载时关闭 SSE 连接（如果存在）
        return () => {
            if (eventSource) {
                eventSource.close();
            }
        };
    }, [uid, serverId, status]);

    // 启动容器
    const handleRun = async () => {
        try {
            await runContainer(uid, serverId);
        } catch (error) {
            Toast.show({ content: "启动失败", icon: "fail" });
        }
    };

    // 停止容器
    const handleStop = async () => {
        try {
            await stopContainer(uid, serverId);
        } catch (error) {
            Toast.show({ content: "停止失败", icon: "fail" });
        }
    };

    // 重启容器
    const handleRestart = async () => {
        try {
            await restartContainer(uid, serverId);
        } catch (error) {
            Toast.show({ content: "重启失败", icon: "fail" });
        }
    };

    const handleConfirm = () => {
        setModalVisible(false);
        if (actionType === "start") {
            handleRun();
        } else if (actionType === "stop") {
            handleStop();
        } else if (actionType === "restart") {
            handleRestart();
        }
    };

    const showModal = (type) => {
        setActionType(type);
        setModalVisible(true);
    };

    // 根据容器状态动态设置颜色
    const getStatusColor = () => {
        switch (containerStatus) {
            case "start":
            case "running":
            case "restart":
                return "green";
            case "kill":
                return "orange";
            case "stop":
            case "die":
                return "red";
            default:
                return "red";
        }
    };

    // 根据容器状态动态设置按钮是否可用
    const isRunning = containerStatus === "start" || containerStatus === "running" || containerStatus === "restart";
    const isProcessing = containerStatus === "kill";
     //paused暂停 restarting重启中 exited停止  die运行错误死亡
    const canDistory = isRunning || containerStatus === "paused" || containerStatus === "restarting" || containerStatus === "die" || containerStatus === "exited";

    return (
        <List>
            {/* 用户信息和设置 */}
            <List.Item
                extra={
                    <SetOutline
                        style={{
                            fontSize: "28px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            if (isRunning) {
                                Toast.show({
                                    content: (<div>辅助正在运行中<br />禁止在登录阶段保存设置<br />保存完需要重启才能生效</div>),
                                    icon: <CiWarning style={{ color: "yellow" }} />,
                                    duration: 5000,
                                    maskClickable: true
                                });
                            }
                            navigate(`/setting?uid=${uid}&serverId=${serverId}`);
                        }}
                    />
                }
                prefix={<Avatar src={demoAvatarImages[0]} />}
                description={`服务器: ${serverName || "未知"} (${serverId}_${uid})`}
            >
                {nickName || "昵称"}
            </List.Item>

            {/* 在线/离线状态和操作按钮 */}
            <List.Item
                prefix={
                    <div
                        style={{
                            width: "12px",
                            height: "12px",
                            borderRadius: "50%",
                            backgroundColor: getStatusColor(),
                        }}
                    ></div>
                }
                arrowIcon={false}
            >
                <Space>
                    {/* 启动按钮 */}
                    <Button color="primary" shape="rounded" onClick={() => showModal("start")} icon={<FaPlay />} disabled={isRunning || isProcessing}>
                        启动
                    </Button>

                    {/* 停止按钮 */}
                    <Button color="danger" shape="rounded" onClick={() => showModal("stop")} icon={<FaStop />} disabled={!canDistory}>
                        停止/移出
                    </Button>

                    {/* 重启按钮 */}
                    <Button color="primary" shape="rounded" onClick={() => showModal("restart")} icon={<MdRestartAlt />} disabled={!isRunning}>
                        重启
                    </Button>
                </Space>
            </List.Item>

            {/* 二次确认弹窗 */}
            <Modal
                visible={modalVisible}
                content={`确认要${actionType === "start" ? "启动" : actionType === "restart" ? "重启" : "停止"}服务器吗?`}
                closeOnAction
                onAction={(action) => {
                    if (action.key === "confirm") {
                        handleConfirm();
                    }
                    setModalVisible(false);
                }}
                onClose={() => setModalVisible(false)}
                actions={[
                    { key: "confirm", text: "确认", primary: true },
                    { key: "cancel", text: "取消" },
                ]}
            />
        </List>
    );
};

export default PersonCard;