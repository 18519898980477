import axiosInstance from "./axiosInstance";

// 获取配置
export const getConfig = async (uid, serverId) => {
    try {
        // 发送 GET 请求到 /api/config，带上 uid 和 server_id 作为查询参数
        const response = await axiosInstance.get(`/config/`, {
            params: {
                uid,
                server_id: serverId,
            },
        });
        return response.data.data.config;
    } catch (error) {
        console.error("Get config error:", error);
        throw error;
    }
};

// 更新配置
export const updateConfig = async (uid, serverId, config) => {
    try {
        // 发送 POST 请求到 /api/config，带上 uid, server_id, 和 config
        const response = await axiosInstance.post("/config/", {
            uid,
            server_id: serverId,
            config,
        });
        // 返回服务器响应的消息
        return response.data;
    } catch (error) {
        console.error("Update config error:", error);
        throw error;
    }
};
