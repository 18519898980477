import React, { useState, useEffect } from "react";
import { List, Switch, Toast, SearchBar } from "antd-mobile";
import { getAllServers, updateServerStatus } from "../../api/admin";
import AdminNavBar from "../../components/AdminNavBar";  // 引入 AdminNavBar

const Servers = () => {
    const [servers, setServers] = useState([]);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        const fetchServers = async () => {
            try {
                const serversData = await getAllServers();
                setServers(serversData);
            } catch (error) {
                Toast.show({ icon: "fail", content: "获取服务器列表失败" });
            }
        };
        fetchServers();
    }, []);

    const handleStatusChange = async (serverID, newStatus) => {
        try {
            await updateServerStatus(serverID, newStatus);
            Toast.show({ icon: "success", content: "服务器状态更新成功" });
            // 更新服务器状态
            setServers(servers.map(server =>
                server.server_id === serverID ? { ...server, status: newStatus } : server
            ));
        } catch (error) {
            Toast.show({ icon: "fail", content: "更新服务器状态失败" });
        }
    };

    const filteredServers = servers
        .filter(server => server.server_name.toLowerCase().includes(filter.toLowerCase()))
        .sort((a, b) => a.server_id - b.server_id); // 按 server_id 排序

    return (
        <div>
            <AdminNavBar title="服务器管理" />

            <SearchBar
                placeholder="搜索服务器名"
                value={filter}
                onChange={(value) => setFilter(value)}
                onCancel={() => setFilter('')}
                onClear={() => setFilter('')}
                style={{ flexShrink: 0 }}
            />

            <List header="服务器列表">
                {filteredServers.map((server) => (
                    <List.Item
                        key={server.server_id}
                        description={server.server_name}
                        extra={
                            <Switch
                                checked={server.status}
                                onChange={(val) => handleStatusChange(server.server_id, val)}
                            />
                        }
                    >
                        {server.server_name}
                    </List.Item>
                ))}
            </List>
        </div>
    );
};

export default Servers;