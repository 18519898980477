import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "../contexts/AuthContext";
import { SettingProvider } from "../contexts/SettingContext";
import Login from "../pages/Login";
import Home from "../pages/Home";
import About from "../pages/About";
import Setting from "../pages/Setting";
import AdminLogin from "../pages/AdminLogin";
import AdminHome from "../pages/admin/home";
import AdminStatus from "../pages/admin/status"
import Servers from "../pages/admin/servers";
import Users from "../pages/admin/users";

const AppRouter = () => {
    const Log = lazy(() => import("../pages/Log"));

    return (
        <AuthProvider>
            <Router>
                <Routes>
                    {/* 登录页面 */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/admin/login" element={<AdminLogin />} />
                    <Route path="/about" element={<About />} />
                    {/* 受保护页面，只有登录后才能访问 */}
                    <Route path="/home" element={<ProtectedRoute component={Home} />} />
                    <Route
                        path="/log"
                        element={
                            <Suspense fallback={<div>加载中...</div>}>
                                <ProtectedRoute component={Log} />
                            </Suspense>
                        }
                    />

                    {/* 管理员受保护页面 */}
                    <Route path="/admin/home" element={<ProtectedAdminRoute component={AdminHome} />} /> {/* 管理员面板 */}
                    <Route path="/admin/status" element={<ProtectedAdminRoute component={AdminStatus} />} /> {/* 管理员面板 */}
                    <Route path="/admin/home/servers" element={<ProtectedAdminRoute component={Servers} />} /> {/* 管理员面板 */}
                    <Route path="/admin/home/users" element={<ProtectedAdminRoute component={Users} />} /> {/* 管理员面板 */}
                    {/* 包裹设置页面的 SettingProvider */}
                    <Route
                        path="/setting"
                        element={
                            <SettingProvider>
                                <ProtectedRoute component={Setting} />
                            </SettingProvider>
                        }
                    />
                    {/* 默认重定向逻辑，根据不同登录状态进行重定向 */}
                    <Route path="/" element={<DefaultRedirect />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

const DefaultRedirect = () => {
    const { isLoggedIn, isAdminLoggedIn } = useAuth(); // 从 AuthContext 获取登录状态

    if (isAdminLoggedIn) {
        return <Navigate to="/admin/home" />;
    }

    if (isLoggedIn) {
        return <Navigate to="/home" />;
    }

    // 如果既不是用户也不是管理员，则清除 localStorage 并跳转到登录页
    localStorage.clear();
    return <Navigate to="/login" />;
};

// 受保护的路由，用于验证是否已登录
const ProtectedRoute = ({ component: Component }) => {
    const { isLoggedIn, isAdminLoggedIn } = useAuth();

    if (isLoggedIn || isAdminLoggedIn) {
        return <Component />;
    }

    // 如果未登录，清除 localStorage 并跳转到登录页
    localStorage.clear();
    return <Navigate to="/login" />;
};

// 受保护的管理员路由，用于验证是否是管理员已登录
const ProtectedAdminRoute = ({ component: Component }) => {
    const { isAdminLoggedIn } = useAuth(); // 从 AuthContext 获取管理员登录状态

    if (isAdminLoggedIn) {
        return <Component />;
    }

    // 如果不是管理员，清除 localStorage 并跳转到管理员登录页
    localStorage.clear();
    return <Navigate to="/admin/login" />;
};

export default AppRouter;
