import React, { createContext, useContext, useState } from 'react';
import { getServers, apiLogin, adminLoginRequest } from '../api/user';
import { Toast } from "antd-mobile";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    // 从 localStorage 获取初始值
    const [username, setUsername] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn") === "true");
    const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(localStorage.getItem("isAdminLoggedIn") === "true");
    const [nickname, setNickname] = useState(localStorage.getItem("nickname") || "");

    const [servers, setServers] = useState([]);
    const [uid, setUid] = useState(parseInt(localStorage.getItem("uid"), 10) || 0);
    const [serverId, setServerId] = useState(parseInt(localStorage.getItem("serverId"), 10) || 0);
    const [serverName, setServerName] = useState(localStorage.getItem("serverName") || "未知服务器");
    const [loading, setLoading] = useState(false);

    // 管理员登录逻辑
    const adminLogin = async (username, password) => {
        try {
            setLoading(true);
            const token = await adminLoginRequest(username, password); // 调用管理员登录 API
            
            setIsAdminLoggedIn(true); // 设置管理员已登录
            localStorage.setItem("isAdminLoggedIn", "true"); // 持久化管理员登录状态
            localStorage.setItem("token", token); // 存储 token
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw new Error("管理员登录失败，请检查用户名和密码");
        }
    };

    // 普通用户登录逻辑
    const login = async (username, password) => {
        try {
            setLoading(true);
            const { uid, servers } = await getServers(username, password); // 从 API 获取服务器列表

            setUid(parseInt(uid, 10));
            setServers(servers);
            setIsLoggedIn(true); // 设置为已登录
            localStorage.setItem("uid", uid); // 持久化 uid
            localStorage.setItem("isLoggedIn", "true"); // 持久化登录状态
            setLoading(false);
            return servers;
        } catch (error) {
            setLoading(false);
            throw new Error("登录失败，请检查用户名和密码");
        }
    };

    // 选择服务器并进行最终的登录
    const selectServerAndLogin = async (serverId, serverName) => {
        try {
            setLoading(true); // 显示加载状态
            const { token, nickname } = await apiLogin(uid, serverId, serverName); // 发送登录请求，接收token和nickname

            // 存储 token 和服务器信息
            localStorage.setItem("token", token);
            localStorage.setItem("serverId", serverId);
            localStorage.setItem("serverName", serverName);
            localStorage.setItem("nickname", nickname);

            // 更新状态
            setServerId(serverId);
            setServerName(serverName);
            setNickname(nickname);

            setLoading(false); // 结束加载状态
        } finally {
            setLoading(false); // 结束加载状态
        }
    };

    const logout = () => {
        setUsername("");
        setServerId("");
        setUid(null);
        setNickname(""); // 清除nickname状态
        setIsLoggedIn(false);
        setIsAdminLoggedIn(false); // 清除管理员登录状态
        localStorage.clear();
    };

    return (
        <AuthContext.Provider value={{ username, setUsername, isLoggedIn, isAdminLoggedIn, servers, loading, login, adminLogin, selectServerAndLogin, serverId, serverName, uid, nickname, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
