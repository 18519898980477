import React, { useState, useEffect } from "react";
import { List, Switch, Toast, SearchBar, Button, SwipeAction, Dialog } from "antd-mobile"; // 引入 Dialog 组件
import { getAllUsers, updateUserStatus, deleteUser } from "../../api/admin"; // 引入API
import AdminNavBar from "../../components/AdminNavBar"; // 引入 AdminNavBar
import "../../assets/Users.css";

const Users = () => {
    const [users, setUsers] = useState([]);
    const [filter, setFilter] = useState(""); // 筛选条件

    // 获取用户列表
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersData = await getAllUsers();
                setUsers(usersData);
            } catch (error) {
                Toast.show({ icon: "fail", content: "获取用户列表失败" });
            }
        };
        fetchUsers();
    }, []);

    // 更新用户状态
    const handleStatusChange = async (uid, server_id, newStatus) => {
        try {
            await updateUserStatus(uid, server_id, newStatus);
            Toast.show({ icon: "success", content: "用户状态更新成功" });

            // 更新本地 users 列表中的状态
            setUsers(users.map((user) => (user.uid === uid && user.server_id === server_id ? { ...user, status: newStatus } : user)));
        } catch (error) {
            Toast.show({ icon: "fail", content: "更新用户状态失败" });
        }
    };

    // 删除用户确认弹窗
    const confirmDelete = (uid, server_id) => {
        Dialog.show({
            content: "确定要删除这个用户吗？",
            closeOnAction: true,
            actions: [
                {
                    key: "delete",
                    text: "删除",
                    bold: true,
                    danger: true,
                    primary: true,
                },
                {
                    key: "cancel",
                    text: "取消",
                },
            ],
            onAction: (action) => {
                if (action.key === "delete") {
                    handleDelete(uid, server_id); // 调用删除操作
                }
            },
        });
    };

    // 删除用户
    const handleDelete = async (uid, server_id) => {
        try {
            await deleteUser(uid, server_id);
            setUsers(users.filter((user) => !(user.uid === uid && user.server_id === server_id)));
            Toast.show({ icon: "success", content: "用户已删除" });
        } catch (error) {
            Toast.show({ icon: "fail", content: "用户未删除" });
        }
    };

    // 复制文本
    const handleCopy = (text) => {
        // 首先尝试使用 Clipboard API
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(text).then(() => {
                Toast.show({ icon: "success", content: "复制成功" });
            }).catch(() => {
                fallbackCopyTextToClipboard(text);
            });
        } else {
            // 使用回退方案
            fallbackCopyTextToClipboard(text);
        }
    };

    // 回退方案：使用 textarea 来实现复制（适用于非HTTPS环境）
    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // 使 textarea 不可见
        textArea.style.position = "fixed";
        textArea.style.opacity = "0";
        textArea.style.left = "-9999px";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand("copy");
            Toast.show({ icon: successful ? "success" : "fail", content: successful ? "复制成功" : "复制失败" });
        } catch (err) {
            Toast.show({ icon: "fail", content: "复制失败" });
        }

        document.body.removeChild(textArea); // 移除 textarea
    };

    // 筛选器处理并按 ID 排序
    const filteredUsers = users
        .filter((user) => user.server_name.toLowerCase().includes(filter.toLowerCase()))
        .sort((a, b) => a.ID - b.ID); // 按 user.ID 排序

    return (
        <div>
            <AdminNavBar title="用户管理" />

            <SearchBar
                placeholder="搜索服务器名"
                value={filter}
                onChange={(value) => setFilter(value)}
                onCancel={() => setFilter("")}
                onClear={() => setFilter("")}
                style={{ flexShrink: 0 }} // 防止输入框被压缩
            />

            <List header="用户列表" className="user-list">
                {filteredUsers.map((user) => (
                    <SwipeAction
                        key={user.ID}
                        rightActions={[
                            {
                                key: "delete",
                                text: "删除",
                                color: "danger",
                                onClick: () => confirmDelete(user.uid, user.server_id), // 改为调用确认弹窗
                            },
                        ]}
                    >
                        <List.Item
                            description={
                                <div className="user-info">
                                    <div className="user-server">{`${user.server_name}  (${user.server_id}_${user.uid})`}</div>
                                    <div className="user-details">
                                        <div className="detail-item">
                                            <span>账号: {user.username}</span>
                                            <Button size="mini" className="copy-button" onClick={() => handleCopy(user.username)}>
                                                复制
                                            </Button>
                                        </div>
                                        <div className="detail-item">
                                            <span>密码: {user.password}</span>
                                            <Button size="mini" className="copy-button" onClick={() => handleCopy(user.password)}>
                                                复制
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            }
                            extra={<Switch checked={user.status} onChange={(val) => handleStatusChange(user.uid, user.server_id, val)} />}
                        >
                            {user.nick_name}
                        </List.Item>
                    </SwipeAction>
                ))}
            </List>
        </div>
    );
};

export default Users;