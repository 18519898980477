import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';  // 引入 useSearchParams 钩子
import { getConfig, updateConfig } from '../api/setting'; // 引入API方法

// 创建 SettingContext
const SettingContext = createContext();

// SettingProvider 组件，用于提供全局设置状态和更新方法
export const SettingProvider = ({ children }) => {
    const [searchParams] = useSearchParams();
    const uid = parseInt(searchParams.get('uid'), 10);
    const serverId = parseInt(searchParams.get('serverId'), 10);


    const [settings, setSettings] = useState({
        general: {},       // 通用设置
        challenge: {},     // 推图设置
        union: {},         // 妖盟设置
        homeland: {},      // 福地设置
        choptree: {},      // 砍树设置
        talent: {},        // 灵脉设置
        other: {},         // 其他设置
    });
    const [activeIndex, setActiveIndex] = useState(0); // 管理当前激活的Tab

    // 初始化时获取配置
    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const config = await getConfig(uid, serverId); // 调用获取配置的API
                setSettings(config); // 设置获取到的配置
            } catch (error) {
                console.error("Error fetching config:", error);
            }
        };

        fetchConfig(); // 调用函数获取配置
    }, [uid, serverId]);

    // 更新设置方法，接受新的设置对象，并合并到现有的设置中
    const updateSettings = (newSettings) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            ...newSettings,
        }));
    };

    // 保存设置到服务器
    const saveSettings = async () => {
        try {
            await updateConfig(uid, serverId, settings); // 调用更新配置的API
            console.log("Settings updated and saved successfully");
        } catch (error) {
            console.error("Error updating config:", error);
            throw error;
        }
    };

    // 使用 Context.Provider 来传递 settings, updateSettings, activeIndex, setActiveIndex, saveSettings
    return (
        <SettingContext.Provider value={{ settings, updateSettings, activeIndex, setActiveIndex, saveSettings }}>
            {children}
        </SettingContext.Provider>
    );
};

// 自定义 Hook，方便在组件中使用 SettingContext
export const useSetting = () => {
    return useContext(SettingContext);
};