import axiosInstance from "./axiosInstance";

// 获取服务器列表
export const getServers = async (username, password) => {
    try {
        // 发送 POST 请求到 /api/get_servers
        const response = await axiosInstance.post("/get_servers", { username, password });
        if (response.data.code === 200) {
            return response.data.data; // 返回服务器列表
        } else {
            throw new Error(response.data.message); // 抛出错误
        }
    } catch (error) {
        throw error;
    }
};

// 登录请求
export const apiLogin = async (uid, serverId, serverName) => {
    try {
        const response = await axiosInstance.post("/login", { uid, server_id: serverId, server_name: serverName });

        if (response.data.code === 200) {
            return response.data.data; // 返回 token
        } else {
            throw new Error(response.data.data.message); // 抛出错误
        }
    } catch (error) {
        throw new Error(error.message);
    }
};

// 管理员登录请求
export const adminLoginRequest = async (username, password) => {
    try {
        const response = await axiosInstance.post("/admin/login", { username, password });
        if (response.data.code === 200) {
            return response.data.data.token; // 返回 token
        } else {
            throw new Error(response.data.message); // 抛出错误
        }
    } catch (error) {
        throw error;
    }
};