import axios from "axios";
import env from "../config"; // 引入 config

const axiosInstance = axios.create({
    baseURL: env.API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器  
axiosInstance.interceptors.response.use(  
    (response) => {
        if(response.data.code===401){
            localStorage.clear();
            window.location.href = "/login"; // 示例：重定向到登录页面  
        }
        return response;  
    },  
    (error) => {
        return Promise.reject(error);  
    }  
);

export default axiosInstance;
