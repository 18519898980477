import axiosInstance from "./axiosInstance";
import env from "../config";

// 获取容器状态
export const getContainerStatus = async (uid, serverId) => {
    try {
        const response = await axiosInstance.post("/container/status", { uid, server_id: serverId });
        return response.data.data.status;
    } catch (error) {
        console.error("Get container status error:", error);
        throw error;
    }
};

// 通过 SSE 订阅容器事件
export const subscribeContainerStatus = (uid, serverId, onMessage) => {
    const eventSource = new EventSource(`${env.API_URL}/container/status_stream?uid=${uid}&server_id=${serverId}`);

    eventSource.onmessage = (event) => {
        const status = event.data; // 从服务器收到的消息
        onMessage(status); // 执行回调函数，传递状态
    };

    eventSource.onerror = (error) => {
        console.error("SSE error:", error);
        eventSource.close(); // 出现错误时关闭连接
    };

    return eventSource;
};

// 启动容器
export const runContainer = async (uid, serverId) => {
    try {
        const response = await axiosInstance.post("/container/run", { uid, server_id: serverId });
        return response.data.code; // 假设返回启动后的容器状态
    } catch (error) {
        console.error("Run container error:", error);
        throw error;
    }
};

// 停止容器
export const stopContainer = async (uid, serverId) => {
    try {
        const response = await axiosInstance.post("/container/stop", { uid, server_id: serverId });
        return response.data.code; // 假设返回停止后的容器状态
    } catch (error) {
        console.error("Stop container error:", error);
        throw error;
    }
};

// 重启容器
export const restartContainer = async (uid, serverId) => {
    try {
        const response = await axiosInstance.post("/container/restart", { uid, server_id: serverId });
        return response.data.code; // 假设返回重启后的容器状态
    } catch (error) {
        console.error("Restart container error:", error);
        throw error;
    }
};