import React, { useState, useEffect } from "react";
import { Card } from "antd-mobile";
import AdminTabBar from "../../components/AdminTabBar";
import { getAllStatus, subscribeAllContainerStatus } from "../../api/admin";
import PersonCard from "../../components/PersonCard";

const AdminStatus = () => {
    const [statusList, setStatusList] = useState([]); // 存储所有状态
    const [loading, setLoading] = useState(true); // 加载状态

    useEffect(() => {
        // 初次加载获取所有状态
        const fetchAllStatus = async () => {
            try {
                const response = await getAllStatus();
                setStatusList(response); // 更新状态列表
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch status list:", error);
                setLoading(false);
            }
        };

        fetchAllStatus();

        // 订阅状态变化的 SSE
        const eventSource = subscribeAllContainerStatus(({ uid, serverId, action }) => {
            setStatusList((prevStatusList) => {
                return prevStatusList.map((item) => {
                    if (item.Uid === uid && item.ServerID === serverId) {
                        return { ...item, Status: action };
                    }
                    return item;
                });
            });
        });

        // 在组件卸载时关闭 SSE 连接
        return () => {
            eventSource.close();
        };
    }, []);

    if (loading) {
        return <div>加载中...</div>;
    }

    // 按照 Uid 排序后的状态列表
    const sortedStatusList = [...statusList].sort((a, b) => a.Uid - b.Uid);

    return (
        <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
            <div style={{ height: "95%", overflowY: "auto" }}>
                {/* 遍历排序后的状态列表，生成多个 PersonCard */}
                {sortedStatusList.map((info) => (
                    <Card key={`${info.Uid}_${info.ServerID}`}>
                        <PersonCard
                            nickName={info.NickName}
                            serverName={info.ServerName}
                            uid={info.Uid}
                            serverId={info.ServerID}
                            status={info.Status}
                        />
                    </Card>
                ))}
            </div>

            {/* 在页面底部添加TabBar */}
            <div style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
                <AdminTabBar />
            </div>
        </div>
    );
};

export default AdminStatus;