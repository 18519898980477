import React, { useState, useEffect } from "react";
import { TabBar } from "antd-mobile";
import { FileOutline, UserOutline } from "antd-mobile-icons";
import { useNavigate, useLocation } from "react-router-dom"; // 引入 useNavigate 和 useLocation

const CommonTabBar = ({ tabs, defaultActiveKey }) => {
    const navigate = useNavigate();
    const location = useLocation(); // 获取当前的路由位置
    const [activeKey, setActiveKey] = useState(defaultActiveKey);

    // 当路由变化时，确保 activeKey 状态与当前路由同步
    useEffect(() => {
        const currentPath = location.pathname.replace("/", "");
        if (currentPath !== activeKey) {
            setActiveKey(currentPath || defaultActiveKey);
        }
    }, [location.pathname, activeKey, defaultActiveKey]);

    const handleTabChange = (key) => {
        if (key !== activeKey) {
            setActiveKey(key);
            navigate(`/${key}`); // 点击时跳转到对应的路径
        }
    };

    return (
        <TabBar activeKey={activeKey} onChange={handleTabChange} safeArea>
            {tabs.map((item) => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge} />
            ))}
        </TabBar>
    );
};

export default () => {
    const tabs = [
        {
            key: "log",
            title: "日志",
            icon: <FileOutline />,
        },
        {
            key: "home",
            title: "我的",
            icon: <UserOutline />,
        },
    ];

    return (
        <>
            <CommonTabBar tabs={tabs} defaultActiveKey="home" />
        </>
    );
};
