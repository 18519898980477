import React from "react";
import { NavBar, Tabs } from "antd-mobile";
import { useNavigate } from "react-router-dom";

const CustomNavBar = ({ title = "标题", onBack, children, tabs, activeKey, onChange, ...props }) => {
    const navigate = useNavigate();

    return (
        <div
            style={{
                position: "sticky",
                top: 0, // 吸顶位置，位于页面顶部
                zIndex: 1000, // 确保 Tabs 在其他内容之上
                backgroundColor: "#fff", // 设置背景色，避免滚动时透明
            }}
        >
            <NavBar
                style={{
                    fontSize: "18px", // 文字大小
                    fontWeight: "bold", // 字体粗细
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // 阴影效果
                }}
                onBack={onBack ? onBack : () => navigate(-1)}
                {...props}
            >
                {title}
            </NavBar>

            {/* 如果传入了 Tabs，显示 Tabs */}
            {tabs && (
                <Tabs
                    activeKey={activeKey}
                    onChange={onChange}
                    style={{
                        "--title-font-size": "14px", // 调整文字大小
                    }}
                >
                    {tabs.map((item) => (
                        <Tabs.Tab title={item.title} key={item.key} />
                    ))}
                </Tabs>
            )}

            {/* 渲染 children 内容 */}
            {children}
        </div>
    );
};

export default CustomNavBar;