import React from "react";
import { Button, Toast, List } from "antd-mobile"; // 引入 List 组件
import AdminTabBar from "../../components/AdminTabBar"; // 引入AdminTabBar组件
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext"; // 引入 AuthContext
import { FaUserFriends } from "react-icons/fa"; // 引入图标
import { FaComputer } from "react-icons/fa6";

const AdminHome = () => {
    const navigate = useNavigate();
    const { logout } = useAuth(); // 使用全局状态

    // 登出逻辑
    const handleLogout = () => {
        logout();
        Toast.show({ content: "已登出" });
        navigate("/admin/login");
    };

    // 跳转到服务器管理页面
    const goToServers = () => {
        navigate("/admin/home/servers");
    };

    // 跳转到用户管理页面
    const goToUsers = () => {
        navigate("/admin/home/users");
    };

    return (
        <div>
            <h1 style={{ textAlign: "center", marginTop: "50px" }}>管理员主页</h1>

            {/* 添加列表进行页面导航 */}
            <List header="管理">
                <List.Item prefix={<FaComputer />} onClick={goToServers}>
                    服务器管理
                </List.Item>
                <List.Item prefix={<FaUserFriends />} onClick={goToUsers}>
                    用户管理
                </List.Item>
            </List>

            {/* 添加一个登出按钮 */}
            <Button block color="danger" style={{ marginTop: "16px" }} onClick={handleLogout}>
                退出登录
            </Button>

            {/* 在页面底部添加TabBar */}
            <div style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
                <AdminTabBar />
            </div>
        </div>
    );
};

export default AdminHome;
