import React, { useState, useEffect } from "react";
import { TabBar } from "antd-mobile";
import { FaHome, FaDocker } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";

const AdminTabBar = ({ tabs, defaultActiveKey }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeKey, setActiveKey] = useState(defaultActiveKey);

    // 同步路由变化和 activeKey
    useEffect(() => {
        const currentPath = location.pathname.replace("/admin/", "");
        if (currentPath !== activeKey) {
            setActiveKey(currentPath || defaultActiveKey);
        }
    }, [location.pathname, activeKey, defaultActiveKey]);

    const handleTabChange = (key) => {
        if (key !== activeKey) {
            setActiveKey(key);
            navigate(`/admin/${key}`);
        }
    };

    return (
        <TabBar activeKey={activeKey} onChange={handleTabChange} safeArea>
            {tabs.map((item) => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge} />
            ))}
        </TabBar>
    );
};

export default () => {
    const tabs = [
        {
            key: "status",
            title: "状态",
            icon: <FaDocker />,
        },
        {
            key: "home",
            title: "主页",
            icon: <FaHome />,
        }
    ];

    return (
        <>
            <AdminTabBar tabs={tabs} defaultActiveKey="home" />
        </>
    );
};