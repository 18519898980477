import React, { useState, useEffect } from "react";
import { Button, Input, Form, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import "../assets/Login.css";
import { useAuth } from "../contexts/AuthContext";

const AdminLogin = () => {
    const navigate = useNavigate();
    const { username, setUsername, isAdminLoggedIn, adminLogin } = useAuth(); // 使用 isAdminLoggedIn
    const [password, setPassword] = useState("");

    // 自动跳转到管理员界面
    useEffect(() => {
        if (isAdminLoggedIn) {
            navigate("/admin/home");
        }
    }, [isAdminLoggedIn, navigate]);

    // 处理管理员登录
    const handleAdminLogin = async () => {
        if (!username || !password) {
            Toast.show({ content: "请输入用户名和密码" });
            return;
        }

        try {
            await adminLogin(username, password); // 调用 adminLogin
            Toast.show({ content: "登录成功，跳转到管理员界面" });
            navigate("/admin/home"); // 登录成功后跳转到管理员界面
        } catch (error) {
            Toast.show({ icon: "fail", content: error.message });
        }
    };

    return (
        <div>
            <div className="floating-balls"></div>

            <div className="login-container">
                {!isAdminLoggedIn ? (
                    <div className="login-form">
                        <h1 style={{ textAlign: "center" }}>管理员登录</h1>
                        <Form layout="horizontal">
                            <Form.Item label="用户名">
                                <Input placeholder="请输入用户名" value={username} onChange={(val) => setUsername(val)} />
                            </Form.Item>
                            <Form.Item label="密码">
                                <Input type="password" placeholder="请输入密码" value={password} onChange={(val) => setPassword(val)} />
                            </Form.Item>
                            <Form.Item>
                                <Button block color="primary" size="large" onClick={handleAdminLogin}>
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                ) : (
                    <Button block className="custom-button" onClick={() => navigate("/admin/home")}>
                        进入管理员界面
                    </Button>
                )}
            </div>
        </div>
    );
};

export default AdminLogin;