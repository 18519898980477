import axiosInstance from "./axiosInstance";
import env from "../config";

// 获取所有服务器
export const getAllServers = async () => {
    try {
        const response = await axiosInstance.get("/admin/servers/get_all");
        return response.data.data.servers; // 返回服务器列表
    } catch (error) {
        console.error("Get all servers error:", error);
        throw error;
    }
};

// 更新服务器状态
export const updateServerStatus = async (serverID, status) => {
    try {
        const response = await axiosInstance.post("/admin/servers/update", {
            server_id: serverID,
            status: status
        });
        return response.data;
    } catch (error) {
        console.error("Update server status error:", error);
        throw error;
    }
};

// 获取所有用户
export const getAllUsers = async () => {
    try {
        const response = await axiosInstance.get("/admin/users/get_all");
        return response.data.data.configs; // 返回用户列表
    } catch (error) {
        console.error("Get all users error:", error);
        throw error;
    }
};

// 更新用户状态
export const updateUserStatus = async (uid, serverID, status) => {
    try {
        const response = await axiosInstance.post("/admin/users/update", {
            uid: uid,
            server_id: serverID,
            status: status
        });
        return response.data;
    } catch (error) {
        console.error("Update user status error:", error);
        throw error;
    }
};

// 删除用户
export const deleteUser = async (uid, serverID) => {
    try {
        const response = await axiosInstance.delete("/admin/users/user", {
            data: {
                uid: uid,
                server_id: serverID
            }
        });
        return response.data;
    } catch (error) {
        console.error("Update user status error:", error);
        throw error;
    }
};

// 获取所有状态
export const getAllStatus = async () => {
    try {
        const response = await axiosInstance.get("/admin/status/all_status");
        return response.data.data;
    } catch (error) {
        console.error("Get all users error:", error);
        throw error;
    }
};

// 通过 SSE 订阅容器事件
export const subscribeAllContainerStatus = (onMessage) => {
    const eventSource = new EventSource(`${env.API_URL}/admin/status/all_status_stream`);

    eventSource.onmessage = (event) => {
        // 解析收到的数据
        const data = JSON.parse(event.data);
        const { uid, server_id, action } = data;

        // 调用回调函数处理消息
        onMessage({ uid, serverId: server_id, action });
    };

    eventSource.onerror = (error) => {
        console.error("SSE error:", error);
        eventSource.close();
    };

    return eventSource;
};