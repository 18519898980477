import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Image, Space, Swiper } from "antd-mobile";
import CustomNavBar from "../components/CustomNavBar";

const About = () => {
    const navigate = useNavigate(); // 用于导航
    const handleBack = () => {
        navigate("/login");
    };

    const createAccountLink = "https://wxshare1.37.com/dist/dzg/zzb-20220512/?hd_referer=jsb&game_id=784";
    const changePasswordLink = "https://h5.37.com/app_password.html";

    const images = [0, 1, 2].map((index) => require(`../assets/about/${index}.webp`));

    return (
        <div>
            {/* 顶部导航 */}
            <CustomNavBar title="帮助" onBack={handleBack}/>

            {/* 按钮横向排列 */}
            <Space justify="center" block style={{ gap: "10px", marginTop: "20px" }}>
                <Button
                    color="primary"
                    size="large"
                    onClick={() => window.open(createAccountLink, "_blank")}
                >
                    微信小程序转app
                </Button>

                <Button
                    color="warning"
                    size="large"
                    onClick={() => window.open(changePasswordLink, "_blank")}
                >
                    修改密码
                </Button>
            </Space>

            {/* 走马灯循环播放本地图片 */}
            <Space direction="vertical" block style={{ marginTop: "20px" }}>
                <Swiper
                    loop
                    autoplay
                    onIndexChange={(i) => {
                        console.log(i, "onIndexChange1");
                    }}
                    style={{
                        "--border-radius": "8px"
                    }}
                    defaultIndex={1}
                >
                    {images.map((img, index) => (
                        <Swiper.Item key={index}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center", // 横向居中
                                    padding: "5px",
                                    width: "80%",
                                    margin: "0 auto" // 确保 div 在容器中居中
                                }}
                            >
                                <Image src={img} style={{ borderRadius: "8px", width: "100%" }} />
                            </div>
                        </Swiper.Item>
                    ))}
                </Swiper>
            </Space>
        </div>
    );
};

export default About;
