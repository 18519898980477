import React from "react";
import { NavBar } from "antd-mobile";
import { useNavigate } from "react-router-dom";

const AdminNavBar = ({ title = "标题", onBack, ...props }) => {
    const navigate = useNavigate();

    return (
        <div
            style={{
                position: "sticky",
                top: 0, // 吸顶位置，位于页面顶部
                zIndex: 1000, // 确保 NavBar 在其他内容之上
                backgroundColor: "#fff", // 设置背景色，避免滚动时透明
            }}
        >
            <NavBar
                style={{
                    fontSize: "18px", // 文字大小
                    fontWeight: "bold", // 字体粗细
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // 阴影效果
                }}
                onBack={onBack ? onBack : () => navigate(-1)}
                {...props}
            >
                {title}
            </NavBar>
        </div>
    );
};

export default AdminNavBar;