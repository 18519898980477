import React, { useState, useEffect } from "react";
import { List, Checkbox, Button, Input, Form, Toast, Picker, FloatingBubble } from "antd-mobile";
import { QuestionCircleFill } from "antd-mobile-icons";
import { useNavigate } from "react-router-dom";
import "../assets/Login.css";
import { useAuth } from "../contexts/AuthContext";

const Login = () => {
    const navigate = useNavigate();
    const { isLoggedIn, servers, loading, login, selectServerAndLogin } = useAuth();
    const [loginUsername, setLoginUsername] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [loginRememberPassword, setLoginRememberPassword] = useState(false);
    const [cookieAccounts, setCookieAccounts] = useState({});  // 存在cookies里面的账号密码
    const [suggestions, setSuggestions] = useState([]);  // 联想建议的账号列表

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const accounts = getAccountCookies();
        const accountNames = Object.keys(accounts);
        setCookieAccounts(accounts);
        setSuggestions(accountNames);
    }, []);

    const handleUsernameChange = (value) => {
        // 根据输入值过滤建议数据
        setLoginUsername(value);
        const accountNames = Object.keys(cookieAccounts);
        if (value!=null && value.length>0) {
          const filteredSuggestions = accountNames.filter(item => item.toLowerCase().includes(value.toLowerCase()));
          setSuggestions(filteredSuggestions);
        } else {
          setSuggestions(accountNames);
        }
    };
    const handleUsernameSelect = (name) => {
        // 联想列表中,选择一个用户名
        const pwd = cookieAccounts[name] ?? '';
        setLoginUsername(name);
        setLoginPassword(pwd);
        setSuggestions([]);
    };


    // 处理登录并获取服务器列表
    const handleLogin = async () => {
        if (!loginUsername || !loginPassword) {
            Toast.show({ content: "请输入用户名和密码" });
            return;
        }
        try {
            await login(loginUsername, loginPassword);
            Toast.show({ content: "登录成功，请选择服务器" });
            if (loginRememberPassword) {
                setAccountCookie(loginUsername, loginPassword, 7);
            }
            setVisible(true); // 打开 Picker
        } catch (error) {
            Toast.show({ icon: "fail", content: error.message });
        }
    };

    // 选择服务器并完成登录
    const handleServerSelection = async (serverId, serverName) => {
        try {
            await selectServerAndLogin(serverId, serverName);
            Toast.show({ content: "服务器选择成功" });
            navigate("/home");
        } catch (error) {
            Toast.show({ content: `${error.message}`, icon: "fail" }); // 显示错误提示
        
            localStorage.clear()
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    };

    return (
        <div>
            <div className="floating-balls"></div>

            <div className="login-container">
                {!isLoggedIn ? (
                    <div className="login-form">
                        <h1 style={{ textAlign: "center" }}>登录</h1>
                        <Form layout="horizontal">
                            <Form.Item label="用户名">
                                <div style={{ position: 'relative' }}>
                                    <Input placeholder="请输入用户名" value={loginUsername} onChange={handleUsernameChange} />
                                    {suggestions.length > 0 && (
                                        <List style={{
                                            position: 'absolute',
                                            top: '100%',
                                            width: '100%',
                                            background: '#fff',
                                            border: '1px solid #ccc',
                                            zIndex: 10,
                                            borderRadius: '8px', // 添加圆角
                                            overflow: 'hidden', // 防止圆角被内容撑开
                                            }}
                                        >
                                        {suggestions.map((suggestion, index) => (
                                            <List.Item
                                                key={index}
                                                arrowIcon={false}
                                                onClick={() => handleUsernameSelect(suggestion)}
                                            >
                                                {suggestion}
                                            </List.Item>
                                        ))}
                                        </List>
                                    )}
                                    </div>
                            </Form.Item>
                            <Form.Item label="密码">
                                <Input type="password" placeholder="请输入密码" value={loginPassword} onChange={(val) => setLoginPassword(val)} />
                            </Form.Item>
                            <Form.Item label="记住密码" help="开启后将在本浏览器中记住密码,方便下次登录">
                                <Checkbox checked={loginRememberPassword} onChange={(checked) => setLoginRememberPassword(!loginRememberPassword)}>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Button block color="primary" size="large" onClick={handleLogin}>
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                ) : (
                    <Button block className="custom-button" onClick={() => setVisible(true)}>
                        选择服务器
                    </Button>
                )}
            </div>

            <Picker
                loading={loading}
                columns={[servers.map((server) => ({ label: server.serverName, value: server.serverId }))]} // 显示服务器列表
                visible={visible}
                onClose={() => setVisible(false)}
                onConfirm={(val) => {
                    const selectedServerId = Array.isArray(val) ? val[0] : val;
                    const selectedServer = servers.find((server) => server.serverId === selectedServerId);
                    const selectedServerName = selectedServer ? selectedServer.serverName : "未知服务器";
                    handleServerSelection(selectedServerId, selectedServerName);
                }}
            />

            <FloatingBubble
                axis="x"
                magnetic="x"
                style={{
                    "--initial-position-top": "24px",
                    "--initial-position-right": "24px",
                    "--edge-distance": "24px",
                }}
                onClick={() => navigate("/about")}
                className="floating-bubble-animation" // 应用动画类
            >
                <QuestionCircleFill className="question-circle-animation" /> {/* 应用动画类 */}
            </FloatingBubble>
        </div>
    );
};

//存储cookie
export const setAccountCookie = (name, pwd, expiryDate=7) => {   //expiryDate是限期
    let d = new Date();
    d.setTime(d.getTime()+(expiryDate*24*60*60*1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = "xddq_" + name + "=" + pwd + "; " + expires;
}
export const getAccountCookies = () => {
    let l = {};
    let arr = document.cookie.split("; ")
    for(let i=0; i<arr.length; i++){
        let arr1 = arr[i].split("=")
        let name = arr1[0] ?? '';
        let pwd = arr1[1] ?? '';
        if (name.startsWith("xddq_")) {
            name = name.slice(5);
            l[name] = pwd;
        }
    }
    return l
}


export default Login;
