import React, { lazy, Suspense, useState } from "react";
import { FloatingBubble, Toast } from "antd-mobile";
import CustomNavBar from "../components/CustomNavBar";
import { FaSave } from "react-icons/fa";
import { useSetting } from "../contexts/SettingContext"; // 导入 SettingContext

// 使用懒加载导入各个组件
const GeneralTab = lazy(() => import("./setting/general"));
const ChallengeTab = lazy(() => import("./setting/challenge"));
const UnionTab = lazy(() => import("./setting/union"));
const HomelandTab = lazy(() => import("./setting/homeland"));
const ChoptreeTab = lazy(() => import("./setting/choptree"));
const TalentTab = lazy(() => import("./setting/talent"));
const OtherTab = lazy(() => import("./setting/other"));

const Setting = () => {
    const { activeIndex, setActiveIndex, saveSettings } = useSetting(); // 使用 context 中的设置和保存方法
    const [isCooldown, setIsCooldown] = useState(false); // 增加冷却状态

    const handleSave = async () => {
        if (isCooldown) return; // 如果在冷却中则直接返回

        setIsCooldown(true); // 进入冷却状态
        try {
            await saveSettings(); // 调用 context 中的 saveSettings 方法
            Toast.show({
                content: "设置已保存",
            });
        } catch (error) {
            Toast.show({
                content: "保存失败",
            });
            console.error("Error saving settings:", error);
        } finally {
            // 在3秒后解除冷却状态
            setTimeout(() => setIsCooldown(false), 3000);
        }
    };

    const tabItems = [
        { key: "general", title: "通用", content: <GeneralTab /> },
        { key: "challenge", title: "推图", content: <ChallengeTab /> },
        { key: "union", title: "妖盟", content: <UnionTab /> },
        { key: "homeland", title: "福地", content: <HomelandTab /> },
        { key: "choptree", title: "砍树", content: <ChoptreeTab /> },
        { key: "talent", title: "灵脉", content: <TalentTab /> },
        { key: "other", title: "其他", content: <OtherTab /> },
    ];

    return (
        <div>
            {/* 使用 CustomNavBar 传递 Tabs */}
            <CustomNavBar
                title="设置"
                tabs={tabItems}
                activeKey={tabItems[activeIndex].key}
                onChange={(key) => {
                    const index = tabItems.findIndex((item) => item.key === key);
                    setActiveIndex(index);
                }}
            />

            {/* Tab 对应的内容渲染 */}
            <div>
                <Suspense fallback={<div>加载中...</div>}>
                    {tabItems[activeIndex].content}
                </Suspense>
            </div>

            {/* 保存按钮，使用 FloatingBubble 固定在屏幕右下角 */}
            <FloatingBubble
                axis="xy" // 允许在 x 和 y 轴上拖动
                style={{
                    "--initial-position-bottom": "24px", // 距离底部 24px
                    "--initial-position-right": "24px", // 距离右侧 24px
                    "--edge-distance": "24px",
                    zIndex: 1000, // 确保它位于其他内容之上
                    opacity: isCooldown ? 0.5 : 1, // 冷却时降低透明度
                    pointerEvents: isCooldown ? "none" : "auto", // 冷却时禁用点击
                }}
                onClick={handleSave} // 点击事件
            >
                <FaSave fontSize={24} />
            </FloatingBubble>
        </div>
    );
};

export default Setting;
